export default {
  name: 'CustomerDialogEdit',
  data() {
    return {
      itemsCountries: [],
      itemsStates: [],
      screenWidth: 0,
      texts: '',
      images: [],
      lengthPage: 0,
      iCurrentPage: 1,
      documentName: "",
      documentLabel: "",
      documentFile: null,
      bLoading: false,
    }
  },
  beforeMount() {
    this.texts = FILE.warehouseTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit('setDialogAddDocument', {
        active: false
      });
      this.documentName = "";
      this.documentLabel = "";
      this.documentFile = null;
    },
    deleteDocument: function () {
      this.documentLabel = "";
      this.documentFile = null;
    },
    uploadFilesWarehouse: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.documentLabel = files[0].name;
          this.documentFile = files[0];
          // this.sendFileWarehouse(files[0], files[0].name);
        });
      }
    },
    sendFileWarehouse: function () {
      this.bLoading = true;

      const payload = { sName: this.documentName };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses/${this.$route.params.id}/files`,
        payload,
        config
      )
        .then((response) => {
          this.sendImage(response.data.results.sBorderWarehousesFileId);
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
    sendImage(id) {
      const self = this;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      var form = new FormData();
      form.append("file", this.documentFile);

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses/${this.$route.params.id}/files/${id}`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.close();
          this.$store.commit("refresher", true);
          // this.getFiles();
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
   
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogAddDocument() {
      return this.$store.state.dialogAddDocument;
    },
    validateForm() {
      return (
        this.latitude !== '' &&
        this.longitude !== '' &&
        this.blueBook !== '' &&
        this.description !== ''
      )
    }
  },
  watch: {
    dialogAddDocument: function () {
      if (this.dialogAddDocument) {
        this.texts = FILE.warehouseTexts[this.selectLanguage];
      }
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.warehouseTexts[this.selectLanguage];
      }
    }
  }
}